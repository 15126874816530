import React from 'react';
import './TOCItem.css';

interface TOCItem {
  id: string;
  title: string;
  children?: TOCItem[];
}

interface TOCItemProps {
  item: TOCItem;
  depth: number;
  onItemHover: (id: string | null) => void;
}

const TOCItemComponent: React.FC<TOCItemProps> = ({ 
  item, 
  depth,
  onItemHover
}) => {
  return (
    <div className="toc-item">
      <div className="toc-item-container">
        <div className="toc-guideline"></div>
        <div className="toc-item-content">
          <div 
            className="toc-item-title"
            onClick={() => {
              const element = document.getElementById(item.id);
              element?.scrollIntoView({ behavior: 'smooth' });
            }}
            onMouseEnter={() => onItemHover(item.id)}
            onMouseLeave={() => onItemHover(null)}
          >
            {item.title}
          </div>
          {item.children && item.children.length > 0 && (
            <div className="toc-item-children">
              {item.children.map((child) => (
                <TOCItemComponent 
                  key={child.id} 
                  item={child} 
                  depth={depth + 1}
                  onItemHover={onItemHover}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TOCItemComponent; 