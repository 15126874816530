import React, { useState, useRef, useEffect } from 'react';
import RecursiveItem from './components/RecursiveItem.tsx';
import './canvas.css';
import { search } from './search.ts';

interface Transform {
  x: number;
  y: number;
  scale: number;
}

interface CanvasItem {
  id: string;
  title: string;
  text: string;
  children?: CanvasItem[];
}

interface CanvasProps {
  items: CanvasItem[];
  onItemsUpdate: (items: CanvasItem[]) => void;
  hoveredId?: string;
}

interface PrefetchCache {
  [parentId: string]: {
    items: CanvasItem[];
    status: 'loading' | 'ready';
    promise?: Promise<void>;
    resolve?: () => void;
    reject?: (error: any) => void;
  };
}

const PREFETCH_DEPTH = 2; // Adjust this number to change how many layers to prefetch

const Canvas: React.FC<CanvasProps> = ({ items, onItemsUpdate, hoveredId }) => {
  // Initialize transform with x,y offset to center first item
  const defaultTransform = { x: 300, y: 10, scale: 1 };
  const [transform, setTransform] = useState<Transform>(defaultTransform);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [prefetchCache, setPrefetchCache] = useState<PrefetchCache>({});

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleWheelEvent = (e: WheelEvent) => {
      e.preventDefault();

      // Handle zooming with Ctrl/Cmd + scroll
      if (e.ctrlKey || e.metaKey) {
        const scaleChange = e.deltaY < 0 ? 1.02 : 0.98;
        
        const rect = container.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        setTransform(prev => {
          const newScale = Math.min(Math.max(prev.scale * scaleChange, 0.1), 5);
          
          const mouseXBeforeZoom = (mouseX - prev.x) / prev.scale;
          const mouseYBeforeZoom = (mouseY - prev.y) / prev.scale;
          const mouseXAfterZoom = (mouseX - prev.x) / newScale;
          const mouseYAfterZoom = (mouseY - prev.y) / newScale;
          
          return {
            scale: newScale,
            x: prev.x + (mouseXAfterZoom - mouseXBeforeZoom) * newScale,
            y: prev.y + (mouseYAfterZoom - mouseYBeforeZoom) * newScale
          };
        });
      } else {
        // Pan with scroll wheel
        setTransform(prev => ({
          ...prev,
          x: prev.x - (e.shiftKey ? e.deltaY : e.deltaX) * 1.5, // Horizontal pan (with shift or horizontal scroll)
          y: prev.y - (!e.shiftKey ? e.deltaY : 0) * 1.5  // Vertical pan (without shift)
        }));
      }
    };

    // Add non-passive wheel event listener
    container.addEventListener('wheel', handleWheelEvent, { passive: false });

    // Cleanup
    return () => {
      container.removeEventListener('wheel', handleWheelEvent);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    // Only enable dragging with space bar held or middle mouse button
    if (e.button === 1 || e.altKey) {
      e.preventDefault();
      setIsDragging(true);
      setDragStart({ x: e.clientX - transform.x, y: e.clientY - transform.y });
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setTransform(prev => ({
        ...prev,
        x: (e.clientX - dragStart.x) * 1.5, // Added multiplier for more sensitive dragging
        y: (e.clientY - dragStart.y) * 1.5  // Added multiplier for more sensitive dragging
      }));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const prefetchLayers = async (parentId: string, parentText: string, depth = 0, updateUI = false) => {
    if (depth >= PREFETCH_DEPTH) return;

    // If ready and just need UI update:
    if (updateUI && prefetchCache[parentId]?.status === 'ready') {
      onItemsUpdate(currentItems => {
        const addChildrenToItem = (items: CanvasItem[]): CanvasItem[] => {
          return items.map(item => {
            if (item.id === parentId) {
              return {
                ...item,
                children: prefetchCache[parentId].items
              };
            }
            if (item.children) {
              return {
                ...item,
                children: addChildrenToItem(item.children)
              };
            }
            return item;
          });
        };
        
        return addChildrenToItem([...currentItems]);
      });
      return;
    }

    // If loading, wait for the promise
    if (prefetchCache[parentId]?.status === 'loading') {
      await prefetchCache[parentId]!.promise;
      if (updateUI && prefetchCache[parentId]?.status === 'ready') {
        onItemsUpdate(currentItems => {
          const addChildrenToItem = (items: CanvasItem[]): CanvasItem[] => {
            return items.map(item => {
              if (item.id === parentId) {
                return {
                  ...item,
                  children: prefetchCache[parentId].items
                };
              }
              if (item.children) {
                return {
                  ...item,
                  children: addChildrenToItem(item.children)
                };
              }
              return item;
            });
          };
          return addChildrenToItem([...currentItems]);
        });
      }
      return;
    }

    // Start new prefetch
    let resolvePromise: () => void;
    let rejectPromise: (error: any) => void;
    const loadingPromise = new Promise<void>((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });

    setPrefetchCache(prev => ({
      ...prev,
      [parentId]: { 
        items: [], 
        status: 'loading',
        promise: loadingPromise,
        resolve: resolvePromise!,
        reject: rejectPromise!
      }
    }));

    try {
      const results = await search(parentText);
      const cleanResponse = results.response.replace(/^```json\n/, '').replace(/\n```$/, '');
      const stories = JSON.parse(cleanResponse).items;
      
      const prefetchedItems = stories.map((story, index) => ({
        id: `${parentId}-${index + 1}`,
        title: story.title,
        text: story.body,
        children: []
      }));

      setPrefetchCache(prev => ({
        ...prev,
        [parentId]: { 
          items: prefetchedItems, 
          status: 'ready',
          promise: prev[parentId]!.promise,
          resolve: prev[parentId]!.resolve,
          reject: prev[parentId]!.reject
        }
      }));

      // Resolve the promise to signal completion
      prefetchCache[parentId]?.resolve?.();

      if (updateUI) {
        onItemsUpdate(currentItems => {
          const addChildrenToItem = (items: CanvasItem[]): CanvasItem[] => {
            return items.map(item => {
              if (item.id === parentId) {
                return {
                  ...item,
                  children: prefetchedItems
                };
              }
              if (item.children) {
                return {
                  ...item,
                  children: addChildrenToItem(item.children)
                };
              }
              return item;
            });
          };
          
          return addChildrenToItem([...currentItems]);
        });
      }

      // Continue prefetching deeper layers in the background
      if (depth < PREFETCH_DEPTH - 1) {
        await Promise.all(prefetchedItems.map(item => 
          prefetchLayers(item.id, item.text, depth + 1, false)
        ));
      }
    } catch (error) {
      console.error('Error prefetching:', error);
      setPrefetchCache(prev => {
        const newCache = { ...prev };
        delete newCache[parentId];
        return newCache;
      });
      prefetchCache[parentId]?.reject?.(error);
      if (updateUI) {
        throw error;
      }
    }
  };

  const prefetchNextLayer = (parentId: string, parentText: string) => {
    prefetchLayers(parentId, parentText);
  };

  const handleAddChild = async (parentId: string, parentText: string) => {
    try {
      // Always fetch with updateUI=true for direct user actions
      await prefetchLayers(parentId, parentText, 0, true);
    } catch (error) {
      console.error('Error adding children:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const results = await search('Most important three news stories of today');
        const cleanResponse = results.response.replace(/^```json\n/, '').replace(/\n```$/, '');
        const stories = JSON.parse(cleanResponse).items;
        const newItems = stories.map((story, index) => ({
          id: `story-${index + 1}`,
          title: story.title,
          text: story.body,
          children: []
        }));
        onItemsUpdate(newItems);
        
        // Prefetch layers for all initial items simultaneously (without UI updates)
        await Promise.all(newItems.map(item =>
          prefetchLayers(item.id, item.text, 0, false)
        ));
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };
  
    // Only fetch on initial mount
    if (items.length === 1 && items[0].title === 'Loading today\'s news...') {
      fetchSearchResults();
    }
  }, []); // Remove onItemsUpdate dependency since we only want this to run once

  useEffect(() => {
    // Scroll to top on page refresh
    window.scrollTo(0, 0);
  }, []);
  
  const findItem = (items: CanvasItem[], id: string): CanvasItem | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const isItemHovered = (itemId: string): boolean => {
    if (!hoveredId) return false;

    // Find the hovered item
    const hoveredItem = findItem(items, hoveredId);
    if (!hoveredItem) return false;

    // If this is the hovered item or one of its children, highlight it
    if (itemId === hoveredId) return true;

    // Check if itemId is a child of hoveredItem
    const isChild = (parent: CanvasItem): boolean => {
      if (!parent.children) return false;
      return parent.children.some(child => 
        child.id === itemId || (child.children && isChild(child))
      );
    };

    return isChild(hoveredItem);
  };

  const mapItemToRecursiveProps = (item: CanvasItem): RecursiveItemProps => {
    return {
      id: item.id,
      title: item.title,
      text: item.text,
      scale: transform.scale,
      onAddChild: handleAddChild,
      isHovered: isItemHovered(item.id),
      isPrefetching: prefetchCache[item.id]?.status === 'loading',
      children: item.children?.map(child => ({
        ...child,
        scale: transform.scale,
        onAddChild: handleAddChild,
        isHovered: isItemHovered(child.id),
        isPrefetching: prefetchCache[child.id]?.status === 'loading'
      }))
    };
  };

  const renderItems = (items: CanvasItem[]) => {
    return items.map((item) => (
      <RecursiveItem
        key={item.id}
        {...mapItemToRecursiveProps(item)}
      />
    ));
  };

  return (
    <div 
      ref={containerRef}
      className={`canvas-container ${isDragging ? 'dragging' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div 
        className={`canvas-item ${isDragging ? 'dragging' : ''}`}
        style={{
          transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.scale})`
        }}
      >
        <h1 className="canvas-date">{new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}</h1>
        {renderItems(items)}
      </div>
    </div>
  );
};

export default Canvas;
