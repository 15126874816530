import React, { useState, useEffect } from 'react';
import './MobileModal.css';

const MobileModal: React.FC = () => {
  const [showModal, setShowModal] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setShowModal(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!showModal) return null;

  return (
    <div className="mobile-modal-overlay">
      <div className="mobile-modal">
        <h2>Desktop View Recommended</h2>
        <p>This experience is best viewed on desktop</p>
      </div>
    </div>
  );
};

export default MobileModal; 