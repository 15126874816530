import React, { useState } from 'react';
import './App.css';
import Canvas from './Canvas.tsx';
import TableOfContents from './TableOfContents.tsx';
import About from './components/About.tsx';
import MobileModal from './components/MobileModal.tsx';

function App() {
  const [items, setItems] = useState([{
    id: '1',
    title: 'Loading today\'s news...',
    children: []
  }]);
  const [hoveredId, setHoveredId] = useState(null);

  // Prevent actual zoom
  const preventZoom = (e) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
    }
  };

  // Add event listeners on mount
  React.useEffect(() => {
    document.addEventListener('wheel', preventZoom, { passive: false });

    return () => {
      document.removeEventListener('wheel', preventZoom);
    };
  }, []);

  const handleItemsUpdate = (newItems) => {
    setItems(newItems);
  };

  const handleHover = (id) => {
    console.log('Hovering:', id);
    setHoveredId(id);
  };

  return (
    <> 
      <MobileModal />
      <TableOfContents 
        items={items} 
        onHover={handleHover}
      />
      <About />
      <Canvas 
        onItemsUpdate={handleItemsUpdate} 
        items={items}
        hoveredId={hoveredId} 
      />
    </>
  );
}

export default App;
