import React, { useState, useEffect } from 'react';
import './RecursiveItem.css';

interface RecursiveItemProps {
  id: string;
  title: string;
  text: string;
  scale: number;
  children?: RecursiveItemProps[];
  onAddChild: (parentId: string, text: string) => void;
  isPrefetching?: boolean;
}

const RecursiveItem: React.FC<RecursiveItemProps> = ({ 
  id, 
  title, 
  text, 
  scale, 
  children = [], 
  onAddChild, 
  isPrefetching = false 
}) => {
    const [showHint, setShowHint] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasBeenClicked, setHasBeenClicked] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
  
    const handleClick = async (id: string, text: string) => {
        if (hasBeenClicked || loading) return;
        
        setLoading(true);
        setHasBeenClicked(true);
        
        try {
            await onAddChild(id, text);
            setIsExpanded(true);
        } catch (error) {
            console.error('Error expanding item:', error);
            // Reset states on error so user can try again
            setHasBeenClicked(false);
        } finally {
            setLoading(false);
        }
    }

    // Reset states when children change
    useEffect(() => {
        if (children.length > 0 && loading) {
            setLoading(false);
            setIsExpanded(true);
        }
    }, [children.length, loading]);

    const hasChildren = children.length > 0;
    const isZoomedOut = scale < 0.7;

    const renderChildren = () => {
        if (loading || (hasBeenClicked && !hasChildren)) {
            return (
                <div className="recursive-item recursive-item-hint recursive-item-hint--loading">
                    <p className={`recursive-item-title ${isZoomedOut ? 'recursive-item-title--zoomed-out' : ''}`}>
                        Expanding...
                    </p>
                    {!isZoomedOut && (
                        <>
                            <p className="recursive-item-label">
                                Expanding...
                            </p>
                            <p className="recursive-item-text">
                                {title}
                            </p>
                        </>
                    )}
                </div>
            );
        }
        
        if (hasChildren) {
            return (
                <div className="recursive-item-children">
                    {children.map((child) => (
                        <RecursiveItem 
                            key={child.id} 
                            {...child}
                            scale={scale} 
                            onAddChild={onAddChild} 
                        />
                    ))}
                </div>
            );
        } else if (showHint) {
            return (
                <div className={`recursive-item recursive-item-hint ${isZoomedOut ? 'recursive-item--zoomed-out' : ''}}`}>
                    <p className={`recursive-item-title ${isZoomedOut ? 'recursive-item-title--zoomed-out' : ''}`}>
                        {isPrefetching ? 'Preparing...' : 'Click to expand'}
                    </p>
                    {!isZoomedOut && (
                        <>
                            <p className="recursive-item-label">
                                {isPrefetching ? 'Preparing...' : 'Click to expand'}
                            </p>
                            <p className="recursive-item-text">
                                {title}
                            </p>
                        </>
                    )}
                </div>
            );
        }
        return null;
    }

    return (
        <div className={`recursive-item-container ${loading ? 'is-loading' : ''}`}>
            <div className="highest-level-item">
                <div 
                    className={`recursive-item ${isZoomedOut ? 'recursive-item--zoomed-out' : ''} ${loading ? 'is-loading' : ''}`} 
                    onClick={() => !loading && handleClick(id, text)} 
                    onMouseOver={() => setShowHint(true)} 
                    onMouseLeave={() => setShowHint(false)}
                >
                    <p className={`recursive-item-title ${isZoomedOut ? 'recursive-item-title--zoomed-out' : ''}`}>
                        {title}
                    </p>
                
                    <p className="recursive-item-label">
                        {title}
                    </p>
                    
                    <p className="recursive-item-text">
                        {text}
                    </p>
                </div>
                <div className="guideline">
                </div>
            </div>
            {renderChildren()}
        </div>
    );
};

export default RecursiveItem; 