import React from 'react';
import TOCItemComponent from './components/TOCItem.tsx';
import './TableOfContents.css';

interface TOCItem {
  id: string;
  title: string;
  children?: TOCItem[];
}

interface TableOfContentsProps {
  items?: TOCItem[];
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ items = [] }) => {
  return (
    <div className="table-of-contents">
      <h3 className="toc-header">
        Table of Contents
      </h3>
      <div className="toc-items">
        {items.map((item) => (
          <TOCItemComponent 
            key={item.id} 
            item={item}
            depth={0}
            onItemHover={() => {}}
          />
        ))}
      </div>
    </div>
  );
};

export default TableOfContents;
