import React, { useState } from 'react';
import './About.css';

const About: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className={`about-container ${isHovered ? 'expanded' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <p className="about-text">
          an experimental interface by{' '}
          <a 
            href="https://nonfiction.tech" 
            target="_blank" 
            rel="noopener noreferrer"
            className="nonfiction-link"
          >
            Nonfiction
          </a>
        </p>
      ) : (
        <p className="about-text">About</p>
      )}
    </div>
  );
};

export default About; 